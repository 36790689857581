import React, { createContext, useContext, useState, useEffect } from "react";

const InputParamsContext = createContext();

export const useInputParams = () => useContext(InputParamsContext);

export const InputParamsProvider = ({ children }) => {
  const [inputParams, setInputParams] = useState(null);

  // Load stored data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("inputParams");
    if (!storedData) {
      setInputParams({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
    setInputParams(JSON.parse(storedData));
  }, []);

  // Update stored data in localStorage whenever inputParams changes
  useEffect(() => {
    if (inputParams) {
      localStorage.setItem("inputParams", JSON.stringify(inputParams));
    }
  }, [inputParams]);

  return (
    <InputParamsContext.Provider value={{ inputParams, setInputParams }}>
      {children}
    </InputParamsContext.Provider>
  );
};
