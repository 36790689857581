import React, { useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Graph from "react-vis-network-graph";
import { getGraphInfo } from "../helpers/paths-info-helpers";
import { v4 as uuidv4 } from "uuid";
import {
  ChangeHistoryTwoTone,
  CircleTwoTone,
  Insights,
  SquareTwoTone,
} from "@mui/icons-material";
import { useIsMobile } from "../hooks";

function DxGraph() {
  const navigate = useNavigate();
  const { recordId } = useParams();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const [graphInfo, setGraphInfo] = useState({
    nodes: [],
    edges: [],
    startNode: "",
    inputFindings: "",
  });
  const [graph, setGraph] = useState({ nodes: [], edges: [] });

  const formatNodes = (nodes, startNode, inputFindings) => {
    return nodes.map((node) => {
      if (node === startNode) {
        return {
          color: "#ff9906",
          id: node,
          label: node.length > 25 ? `${node.slice(0, 25)}...` : node,
          shape: "dot",
          title: `Full name: ${node}`, // You can customize this as needed
        };
      }
      if (inputFindings.includes(node)) {
        return {
          color: "#da03b3",
          id: node,
          label: node.length > 25 ? `${node.slice(0, 25)}...` : node,
          shape: "diamond", // it could also be: "box", "ellipse", "diamond", "dot", "star", "triangleDown", "triangle", "square", "icon"
          title: `Full name: ${node}`, // You can customize this as needed
        };
      }
      return {
        color: "#01CACF",
        id: node,
        label: node.length > 25 ? `${node.slice(0, 25)}...` : node,
        shape: "triangle",
        title: `Full name: ${node}`, // You can customize this as needed
      };
    });
  };

  const formatEdges = (edges, startNode, inputFindings) => {
    return edges.map((edge) => {
      if (edge.from === startNode) {
        return {
          color: "#ff9906",
          from: edge.from,
          to: edge.to,
        };
      }
      if (inputFindings.includes(edge.from)) {
        return {
          color: "#da03b3",
          from: edge.from,
          to: edge.to,
        };
      }
      return {
        color: "#01CACF",
        from: edge.from,
        to: edge.to,
      };
    });
  };

  const legend = [
    {
      label: "Diagnóstico probable actual",
      icon: <CircleTwoTone sx={{ color: "#ff9906" }} />,
    },
    {
      label: "Hallazgos actuales del paciente",
      icon: (
        <SquareTwoTone sx={{ transform: "rotate(45deg)", color: "#da03b3" }} />
      ),
    },
    {
      label: "Otros diagnósticos asociados",
      icon: <ChangeHistoryTwoTone sx={{ color: "#01CACF" }} />,
    },
  ];

  useEffect(() => {
    if (searchParams.get("paths_info")) {
      setGraphInfo({ nodes: [], edges: [] });
      const pathsInfo = JSON.parse(searchParams.get("paths_info"));
      const { nodes, edges, startNode } = getGraphInfo(recordId, pathsInfo);
      const inputFindings = JSON.parse(searchParams.get("input_findings"));
      setGraphInfo({ nodes, edges, startNode, inputFindings });
    }
  }, [searchParams, recordId]);

  useEffect(() => {
    if (graphInfo?.nodes.length > 0) {
      const formattedNodes = formatNodes(
        graphInfo?.nodes,
        graphInfo?.startNode,
        graphInfo?.inputFindings
      );
      const formattedEdges = formatEdges(
        graphInfo?.edges,
        graphInfo?.startNode,
        graphInfo?.inputFindings
      );
      setGraph({ nodes: formattedNodes, edges: formattedEdges });
    }
  }, [graphInfo]);

  const options = useMemo(() => {
    return {
      layout: {
        hierarchical: false,
      },
      height: isMobile ? "500px" : "600px",
      nodes: {
        borderWidth: 2,
        font: { size: 25, color: "white", align: "center" },
        labelHighlightBold: true,
      },
      edges: {
        smooth: { type: "continuous", roundness: 0.8 },
        arrows: { to: { enabled: true, scaleFactor: 1.5 } },
      },
      physics: {
        barnesHut: {
          gravitationalConstant: -8000,
          centralGravity: 0.5,
          springLength: 550,
          springConstant: 0.04,
          damping: 0.5, // 0.09
          avoidOverlap: 1.9,
        },
        solver: "barnesHut", // barnesHut or repulsion
      },
      interaction: {
        tooltipDelay: 300,
        hover: true,
        navigationButtons: true,
      },
    };
  }, [isMobile]);

  // const events = {  // Useful for future implementations
  //   select: function (event) {
  //     var { nodes, edges } = event;
  //     console.log(edges);
  //     console.log(nodes);
  //     // highlightNodes(graph, nodes, edges);
  //   },
  // };

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Insights
          sx={{
            fontSize: 50,
            color: "secondary.main",
          }}
        />
        <Stack direction="column">
          <Typography variant="h5">Nube de conexiones</Typography>
          <Typography variant="h3">{recordId}</Typography>
        </Stack>
      </Stack>

      <Typography
        variant="body2"
        sx={{
          fontStyle: "italic",
        }}
      >
        Estás viendo la nube de conexiones de {recordId}, acá puedes explorar
        las relaciones entre los distintos componentes que generan el
        diagnóstico. Para más información, visita la{" "}
        <Box
          component="span"
          color="secondary.main"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            navigate(`/record/${recordId}`);
          }}
        >
          ficha de detalle
        </Box>
        .
      </Typography>
      <Stack direction="column" spacing={0.5}>
        {legend.map((item) => (
          <Stack
            key={item.label}
            direction="row"
            spacing={1.2}
            alignItems="center"
          >
            {item.icon}
            <Typography variant="body1">{item.label}</Typography>
          </Stack>
        ))}
      </Stack>

      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "10px",
        }}
      >
        <Graph
          key={uuidv4()}
          graph={graph}
          options={options}
          // events={events}
          getNetwork={(network) => {
            // console.log(network);
          }}
        />
      </Box>
    </Stack>
  );
}

export default DxGraph;
