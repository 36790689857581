import React, { useState } from "react";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Collapse,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FindingListItem } from "../lists";
import { TransitionGroup } from "react-transition-group";

const ListInputForm = ({
  selected,
  setSelected,
  paramInfo: { name: paramName, type: paramType, placeholder },
  autoCompleteOptions,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const paramValue = inputValue.trim();
    if (!paramValue) return;
    setSelected([...selected, paramValue], paramName, paramType);
    setInputValue("");
  };

  const handleRemove = (index) => {
    const newSelected = selected.filter((_, i) => i !== index);
    setSelected([...newSelected], paramName, paramType);
  };

  return (
    <Stack direction="row">
      <Stack direction="column" sx={{ width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Autocomplete
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              freeSolo
              disablePortal
              options={autoCompleteOptions || []}
              sx={{
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={placeholder}
                  color="tertiary"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "background.paper",
                      color: "text.dark",
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body2" color="text.dark">
                    {option}
                  </Typography>
                </li>
              )}
            />
            <IconButton type="submit" color="primary" size="medium">
              <Add />
            </IconButton>
          </Stack>
        </form>
        <Stack mt={0.5}>
          <TransitionGroup>
            {selected.map((name, index) => (
              <Collapse key={name}>
                <FindingListItem
                  key={name}
                  name={name}
                  handleRemove={() => handleRemove(index)}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </Stack>
      </Stack>
    </Stack>
  );
};

ListInputForm.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  paramInfo: PropTypes.object.isRequired,
  autoCompleteOptions: PropTypes.array,
};

export default ListInputForm;
