import React, { useState, useMemo, useEffect } from "react";
import { Button, Collapse, Stack, Typography, Box } from "@mui/material";
import RawQueryForm from "./rawQuery/RawQueryForm";
import DxTable from "../components/tables/DxTable";
import useInputParamsInfo from "../hooks/useInputParamsInfo";
import { PersonSearchTwoTone } from "@mui/icons-material";
import getClient from "../helpers/get-client";
import { useDxData } from "../components/context/DxDataContext";
import { useInputParams } from "../components/context/InputParamsContext";
import CircularProgress from "@mui/material/CircularProgress";


function RawQuery() {
  const client = getClient();
  const { dxData, setDxData } = useDxData();
  const { inputParams, setInputParams } = useInputParams();
  const [loading, setLoading] = useState(false);
  const handleParamsSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const inputParamsString = JSON.stringify(inputParams);
    client
      .get(
        `${process.env.REACT_APP_BACKEND_URI}/api/medical_analysis/get-dx/`,
        {
          params: { inputParams: inputParamsString },
        }
      )
      .then((response) => {
        console.log("Backend response", response.data);
        setDxData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  };
  console.log();
  const inputParamsInfo = useInputParamsInfo();
  const setInitialParamsInfo = () => {
    setInputParams(
      inputParamsInfo.reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue.name]: currentValue.defaultValue,
        };
      }, {})
    );
  };
  useEffect(() => {
    if (inputParams) {
      return;
    }
    setInitialParamsInfo(); // ToDo: Fix this eslint warning
  }, []); // eslint-disable-line
  const setInputParamsHandler = (paramValue, paramName, paramType) => {
    setInputParams({
      ...inputParams,
      [paramName]: paramValue,
    });
  };
  const columnNames = useMemo(() => {
    if (!dxData?.dx_response) {
      return [];
    }
    return Object.keys(dxData.dx_response);
  }, [dxData]);
  const rowData = useMemo(() => {
    if (!dxData?.dx_response) {
      return [];
    }
    return Object.values(dxData.dx_response);
  }, [dxData]);
  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: { xs: "100%", md: "50%" },
        }}
        alignItems="start"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <PersonSearchTwoTone
            sx={{
              fontSize: 50,
              color: "secondary.main",
            }}
          />
          <Stack direction="column">
            <Typography variant="h5">Sistema de</Typography>
            <Typography variant="h3">Diagnóstico</Typography>
          </Stack>
        </Stack>
        <Collapse in={!!inputParams?.sexo || !!inputParams?.edad}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={setInitialParamsInfo}
          >
            Limpiar campos
          </Button>
        </Collapse>
        {inputParams && (
          <RawQueryForm
            inputParamsInfo={inputParamsInfo}
            inputParams={inputParams}
            setInputParamsHandler={setInputParamsHandler}
            handleParamsSubmit={handleParamsSubmit}
            loading={loading}
          />
        )}
      </Stack>

      {loading && (
        <Box
          sx={{
            width: "80%",
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={120} />
        </Box>
      )}
      {!loading && dxData?.dx_response && (
        <DxTable columnNames={columnNames} rowData={rowData} />
      )}
    </Stack>
  );
}

export default RawQuery;
