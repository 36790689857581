import getClient from "./get-client";

const client = getClient();

const getCsrfToken = async () => {
  try {
    const response = await client.get('/api/user/csrf/', { withCredentials: true });
    return response.data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    return null;
  }
};

const register = async (event, formData, setFormData, setErrorMessage) => {
  event.preventDefault(); // Prevent default form submission
  const csrfToken = await getCsrfToken();

  try {
    await client.post(
      '/api/user/create/',
      formData,
      {
        headers: csrfToken ? { 'X-CSRFToken': csrfToken } : {},
        withCredentials: true,
      }
    );

    setFormData({
      email: '',
      password: '',
      username: '', 
    });

    alert('Registration successful!');
  } catch (error) {
    console.log('Error', error);

    if (error.response && error.response.data) {
      setErrorMessage(error.response.data.detail || 'El correo electrónico ya está registrado');
    } else {
      setErrorMessage('Error desconocido, por favor intente de nuevo.');
    }
  }
};

const login = async (event, formData, setFormData, setErrorMessage) => {
  event.preventDefault(); 
  const csrfToken = await getCsrfToken();

  try {
    const response = await client.post(
      '/api/user/token/',
      {
        email: formData.email,
        password: formData.password,
      },
      {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true, 
      }
    );

    setFormData({
      email: "",
      password: "",
    });

    localStorage.setItem("authToken", response.data["token"]);
    window.location.reload();
  } catch (error) {
    console.log("Error", error);
    setErrorMessage("Usuario o contraseña incorrectos");
  }
};

const logout = () => {
  localStorage.removeItem("authToken");
  window.location.href = "/authentication";
};

export { register, login, logout };
