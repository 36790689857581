// Record.js
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import getClient from "../helpers/get-client";
import { Grid, Stack, Typography } from "@mui/material";
import RecordCard from "../components/cards/RecordCard";
import RecordIndex from "./record/RecordIndex";
import SkeletonCard from "./record/SkeletonCard";
import ActionGrid from "./record/ActionGrid";

const Record = () => {
  const client = getClient();
  const { recordId } = useParams();
  const [recordData, setRecordData] = useState(null);
  const [columnsMetadata, setColumnsMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  const sectionRefs = useRef({});
  const contentRef = useRef(null); // Ref for the scrollable content container

  useEffect(() => {
    setLoading(true);
    setRecordData(null);
    setColumnsMetadata(null);
    client
      .get(
        `${process.env.REACT_APP_BACKEND_URI}api/medical_analysis/get-record-data/`,
        {
          params: { recordId: recordId, recordType: "1" },
        }
      )
      .then((response) => {
        console.log("Response", response.data);
        setRecordData(response.data.record_data);
        setColumnsMetadata(response.data.columns_metadata);
        sectionRefs.current = Object.keys(response.data.record_data).reduce(
          (acc, key) => {
            acc[key] = React.createRef();
            return acc;
          },
          {}
        );
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [recordId]); // eslint-disable-line

  const scrollToSection = (key) => {
    if (sectionRefs.current[key] && contentRef.current) {
      const element = sectionRefs.current[key].current;
      const yOffset = -15; // Adjust this value to set the offset
      const y =
        element.getBoundingClientRect().top +
        contentRef.current.scrollTop -
        contentRef.current.getBoundingClientRect().top +
        yOffset;
      contentRef.current.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <Stack
      direction="row"
      position="relative"
      sx={{
        height: "89vh",
        display: "flex",
      }}
    >
      <RecordIndex
        recordData={recordData}
        loading={loading}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        scrollToSection={scrollToSection}
      />
      <Stack
        spacing={3}
        sx={{
          backgroundColor: "background.paper",
          padding: "1rem",
          margin: { xs: "0 0 0 3.5rem", md: "0" },
          overflowY: "scroll",
          position: "relative",
          width: "100%",
        }}
        ref={contentRef} // Assigning the ref to the scrollable content container
      >
        <Grid
          container
          alignItems="center"
          sx={{
            border: "1px solid",
            padding: "1rem",
          }}
        >
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h2" color="text.dark" align="center">
              {recordId}
            </Typography>
          </Grid>
          <ActionGrid />
        </Grid>
        <Stack direction="column" spacing={2}>
          {loading && (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          )}
          {recordData &&
            Object.entries(recordData).map(([key, value]) => (
              <div key={key} ref={sectionRefs.current[key]}>
                <RecordCard
                  title={columnsMetadata[key].display_name}
                  content={value}
                  metadata={columnsMetadata[key]}
                />
              </div>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Record;
