import React from "react";
import { Stack, Typography } from "@mui/material";
import { HourglassTopTwoTone } from "@mui/icons-material";

function WaitingForAccess() {
  return (
    <Stack
      direction="column"
      spacing={2.5}
      alignItems="center"
      textAlign="center"
    >
      <HourglassTopTwoTone
        sx={{
          color: "secondary.main",
          fontSize: "7rem",
        }}
      />
      <Typography variant="h1">¡Ya no queda nada!</Typography>
      <Typography variant="h5">
        Tu solicitud de acceso ha sido enviada. Te avisaremos cuando esté lista.
      </Typography>
    </Stack>
  );
}

export default WaitingForAccess;
