// UserContext.js
import React, { createContext, useContext } from "react";
import getClient from "../../helpers/get-client";

const client = getClient();

const UserContext = createContext();

export const useCurrentUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if(!localStorage.getItem("authToken")) {
      setLoading(false);
      setCurrentUser(null);
      return;
    }
    client
      .get("/api/user/me/")
      .then(function (response) {
        setCurrentUser(response.data);
      })
      .catch(function (error) {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const value = { currentUser, loading, error };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
