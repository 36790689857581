import React from "react";
import PropTypes from "prop-types";
import { Button, Stack } from "@mui/material";

const DxGenderInput = ({ param, selectedValue, setValue }) => {
  return (
    <Stack direction="row" spacing={1}>
      {param.options.map((option, index) => (
        <Button
          key={option.value}
          variant={selectedValue === option.value ? "contained" : "outlined"}
          onClick={() => {
            setValue(option.value, param.name, param.type);
          }}
          size="large"
        >
          {option.label}
        </Button>
      ))}
    </Stack>
  );
};

DxGenderInput.propTypes = {
  param: PropTypes.object.isRequired,
  selectedValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default DxGenderInput;
