import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Insights, Source } from "@mui/icons-material";
import { useDxData } from "../context/DxDataContext";
import { getPathInfo } from "../../helpers/paths-info-helpers";

const SpecificDxsModal = ({
  openSpecificDxs,
  specificDxs,
  selectedGroup,
  handleGroupClose,
}) => {
  const navigate = useNavigate();
  const { dxData } = useDxData();
  const goToGraph = (specificDx) => {
    const { dxPathsInfo } = getPathInfo(specificDx.name, dxData?.paths_info);
    const encodedPathsInfo = encodeURIComponent(JSON.stringify(dxPathsInfo));
    const encodedInputFindings = encodeURIComponent(
      JSON.stringify(dxData?.input_findings)
    );
    navigate(
      `/graph/${specificDx.name}?paths_info=${encodedPathsInfo}&input_findings=${encodedInputFindings}`
    );
  };

  const actionButtons = (specificDx) => {
    return (
      <Stack direction="row" spacing={0.5}>
        <IconButton
          size="small"
          sx={{
            backgroundColor: "transparent",
            color: "secondary.main",
          }}
          onClick={() => {
            navigate(`/record/${specificDx.name}`);
          }}
        >
          <Source />
        </IconButton>
        <IconButton
          size="small"
          sx={{
            backgroundColor: "transparent",
            color: "secondary.main",
          }}
          onClick={() => {
            goToGraph(specificDx);
          }}
        >
          <Insights />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Modal
      open={openSpecificDxs && specificDxs.length > 0}
      onClose={handleGroupClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.dark",
          boxShadow: 24,
          p: "1rem",
          maxHeight: "90vh",
          overflow: "scroll",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: "0.5rem",
            fontStyle: "italic",
          }}
        >
          Diagnósticos del grupo
        </Typography>
        <Typography
          variant="h5"
          fontWeight={700}
          sx={{
            marginBottom: "1rem",
          }}
        >
          {selectedGroup}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Score</TableCell>
                <TableCell>Specific Dx</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {specificDxs.map((specificDx, index) => (
                <TableRow key={specificDx.name}>
                  <TableCell>
                    <Typography variant="body2">{specificDx.score}</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="column">
                      {specificDx.name}
                      {actionButtons(specificDx)}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

SpecificDxsModal.propTypes = {
  openSpecificDxs: PropTypes.bool,
  specificDxs: PropTypes.array,
  selectedGroup: PropTypes.string,
  handleGroupClose: PropTypes.func,
};

export default SpecificDxsModal;
