import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import ListInputForm from "../../components/forms/ListInputForm";
import {
  DictInputForm,
  DxAgeField,
  DxGenderInput,
  DxNumberField,
} from "../../components/forms";

const RawQueryForm = ({
  inputParamsInfo,
  inputParams,
  setInputParamsHandler,
  handleParamsSubmit,
  loading,
}) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false);
  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const displayInputHeader = (param) => {
    if (param.type === "boolean" || param.type === "number") {
      return "none";
    }
    return "block";
  };

  const personalInfoParams = useMemo(() => {
    return inputParamsInfo.filter(
      (param) => param.name === "edad" || param.name === "sexo"
    );
  }, [inputParamsInfo]);

  const notPersonalInfoParams = useMemo(() => {
    return inputParamsInfo.filter(
      (param) => param.name !== "edad" && param.name !== "sexo"
    );
  }, [inputParamsInfo]);

  const getInputComponent = (param) => {
    if (param.name === "edad") {
      return (
        <DxAgeField
          param={param}
          selectedValue={inputParams[param.name] || ""}
          setValue={setInputParamsHandler}
        />
      );
    } else if (param.name === "sexo") {
      return (
        <DxGenderInput
          param={param}
          selectedValue={inputParams[param.name] || ""}
          setValue={setInputParamsHandler}
        />
      );
    }
    switch (param.type) {
      case "dict":
        return (
          <DictInputForm
            selected={inputParams[param.name]}
            setSelected={setInputParamsHandler}
            paramInfo={param}
            autoCompleteOptions={param.autoCompleteOptions}
          />
        );
      case "list":
        return (
          <ListInputForm
            selected={inputParams[param.name]}
            setSelected={setInputParamsHandler}
            paramInfo={param}
            autoCompleteOptions={param.autoCompleteOptions}
          />
        );
      case "boolean":
        return (
          <Stack direction="row" alignItems="center">
            <Switch
              checked={inputParams[param.name]}
              onChange={(event) => {
                setInputParamsHandler(
                  event.target.checked,
                  param.name,
                  param.type
                );
              }}
            />
            <Typography
              variant="h5"
              sx={{
                marginRight: 2,
                marginLeft: 1,
              }}
            >
              {param.label}
            </Typography>
            <Typography variant="h5" fontWeight={700}>
              {inputParams[param.name] ? "Sí" : "No"}
            </Typography>
          </Stack>
        );
      case "number":
        return (
          <Stack direction="row" alignItems="center" spacing={1.2}>
            <DxNumberField
              value={inputParams[param.name]}
              setValue={(value) => {
                setInputParamsHandler(value, param.name, param.type);
              }}
              maxValue={param.maxValue}
            />
            <Typography variant="h5">{param.name}</Typography>
          </Stack>
        );
      default:
        return <></>;
    }
  };
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        marginBottom: 2,
      }}
    >
      <Stack direction="row" spacing={4}>
        {personalInfoParams.map((param) => (
          <Box key={param.name}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 1,
                fontWeight: 700,
                display: displayInputHeader(param),
              }}
            >
              {param.label}
            </Typography>
            {getInputComponent(param)}
          </Box>
        ))}
      </Stack>
      {notPersonalInfoParams.map((param) => (
        <Box
          key={param.name}
          sx={{
            display: param.advanced
              ? showAdvancedOptions
                ? "block"
                : "none"
              : "block",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              fontWeight: 700,
              display: displayInputHeader(param),
            }}
          >
            {param.label}
          </Typography>
          {getInputComponent(param)}
        </Box>
      ))}
      <Button
        variant="link"
        onClick={toggleAdvancedOptions}
        sx={{
          width: "fit-content",
          display: "block",
          padding: "0 0 0 0.3rem",
        }}
      >
        {showAdvancedOptions
          ? "Ocultar configuraciones avanzadas"
          : "Mostrar configuraciones avanzadas"}
      </Button>
      <Button
        variant="contained"
        color="tertiary"
        onClick={handleParamsSubmit}
        disabled={loading || !inputParams.sexo || !inputParams.edad}
      >
        Consultar
      </Button>
    </Stack>
  );
};

RawQueryForm.propTypes = {
  inputParamsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
      ]),
      type: PropTypes.string.isRequired,
      autoCompleteOptions: PropTypes.array,
      advanced: PropTypes.bool,
      adornment: PropTypes.string,
      maxValue: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  inputParams: PropTypes.object.isRequired,
  setInputParamsHandler: PropTypes.func.isRequired,
  handleParamsSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RawQueryForm;
