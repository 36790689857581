import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const ActionButton = ({ children }) => (
  <Tooltip title="Próximamente 👀" arrow>
    <span>
      <IconButton
        disabled
        aria-label="like"
        size="small"
        sx={{
          background: "transparent",
          color: "text.dark",
        }}
      >
        {children}
      </IconButton>
    </span>
  </Tooltip>
);

export default ActionButton;
