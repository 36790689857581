import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Collapse, Stack, Typography } from "@mui/material";

const CustomRecordCard = ({ title, minHeight, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [collapsedHeight, setCollapsedHeight] = useState(`${minHeight}px`);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkContentHeight = () => {
      const contentHeight = contentRef.current?.clientHeight;
      if (contentHeight > minHeight) {
        setShowButton(true);
        setCollapsedHeight(`${minHeight}px`);
      } else {
        setShowButton(false);
        setCollapsedHeight(`${contentHeight}px`);
      }
    };

    checkContentHeight();
    window.addEventListener("resize", checkContentHeight);

    return () => {
      window.removeEventListener("resize", checkContentHeight);
    };
  }, [minHeight]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const seeMoreButton = (
    <Button
      onClick={toggleExpanded}
      fullWidth
      sx={{
        color: "tertiary.main",
        borderRadius: 0,
        borderTop: "1px solid",
        borderColor: "background.lighter",
        padding: "0.2rem",
      }}
    >
      {isExpanded ? "Ver menos" : "Ver más"}
    </Button>
  );

  return (
    <Stack key={title} direction="column">
      <Box
        sx={{
          backgroundColor: "background.dark",
          padding: "0.2rem 0.8rem",
          width: "fit-content",
          borderRadius: "0.5rem 0.5rem 0 0",
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          border: "1px solid",
          borderColor: "background.dark",
          position: "relative",
        }}
      >
        <Collapse in={isExpanded} collapsedSize={collapsedHeight}>
          <Box
            ref={contentRef}
            sx={{
              padding: "0.5rem 0.8rem",
            }}
          >
            {children}
          </Box>
        </Collapse>
        {showButton && seeMoreButton}
      </Box>
    </Stack>
  );
};

CustomRecordCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  minHeight: PropTypes.number,
};

CustomRecordCard.defaultProps = {
  minHeight: 150,
};

export default CustomRecordCard;
