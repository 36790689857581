const parseValue = (value) => {
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  // ! Not tested, try when needed
  // if (typeof value === "object") {
  //   return Object.values(value).join(", ");
  // }
  return value;
};

export { parseValue };
