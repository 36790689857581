import React, { createContext, useContext, useState, useEffect } from "react";

const DxDataContext = createContext();

export const useDxData = () => useContext(DxDataContext);

export const DxDataProvider = ({ children }) => {
  const [dxData, setDxData] = useState(null);

  // Load stored data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("dxData");
    if (storedData) {
      setDxData(JSON.parse(storedData));
    }
  }, []);

  // Update stored data in localStorage whenever dxData changes
  useEffect(() => {
    localStorage.setItem("dxData", JSON.stringify(dxData));
  }, [dxData]);

  return (
    <DxDataContext.Provider value={{ dxData, setDxData }}>
      {children}
    </DxDataContext.Provider>
  );
};
