import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00CBA7",
    },
    secondary: {
      main: "#FCF0CC",
    },
    tertiary: {
      main: "#508AA8",
    },
    danger: {
      main: "#D62246",
    },
    background: {
      default: "#333745",
      light: "#474A53",
      lighter: "#CCCCCC",
      dark: "#212329",
      paper: "#EEEEEE",
    },
    text: {
      main: "#FFFFFF",
      primary: "#FFFFFF",
      secondary: "#FCF0CC",
      dark: "#1A1A1A",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "white",
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 700,
      color: "white",
    },
    h3: {
      fontSize: "1.75rem",
      color: "white",
    },
    h4: {
      fontSize: "1.5rem",
      color: "white",
    },
    h5: {
      fontSize: "1.2rem",
      color: "white",
    },
    h6: {
      fontSize: "1rem",
      color: "white",
    },
    subtitle1: {
      fontSize: "1rem",
      color: "white",
    },
    subtitle2: {
      fontSize: "0.875rem",
      color: "white",
    },
    body1: {
      fontSize: "1rem",
      color: "white",
    },
    body2: {
      fontSize: "0.875rem",
      color: "white",
    },
    button: {
      fontSize: "1rem",
      color: "white",
    },
    caption: {
      fontSize: "0.75rem",
      color: "white",
    },
    overline: {
      fontSize: "0.75rem",
      color: "white",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "white",
          fontWeight: 700,
        },
        link: {
          color: "#FCF0CC",
          fontWeight: 500,
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline",
          },
          "&:active": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#00CBA7",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            color: "#1A1A1A",
          },
          "& .MuiTableCell-head": {
            color: "white",
            fontWeight: 700,
            backgroundColor: "#00CBA7",
            border: "1px solid white",
          },
          "& .MuiTableCell-body": {
            color: "#FFFFFF",
            backgroundColor: "#474A53",
            border: "1px solid #00CBA7",
          },
          "& .MuiTableRow-root": {},
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#333745",
          color: "#FFFFFF",
          border: "1px solid #00CBA7",
          "&.Mui-expanded": {
            backgroundColor: "#474A53",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#474A53",
          color: "#FFFFFF",
          borderBottom: "1px solid #00CBA7",
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
        content: {
          margin: "12px 0",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#474A53",
          color: "#FFFFFF",
          padding: "8px 16px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#474A53",
          borderRadius: "4px",
          "&:focus": {
            backgroundColor: "#474A53",
          },
        },
        icon: {
          color: "white",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#474A53", // Adjust as per your theme
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#474A53",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#00CBA7",
          },
          "&.Mui-selected": {
            backgroundColor: "#00CBA7",
          },
        },
      },
    },
    // MuiList: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#474A53",
    //     },
    //   },
    // },

    MuiFormControl: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#474A53",
          borderRadius: "4px",
          "&:focus": {
            backgroundColor: "#474A53",
          },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#474A53",
    //       borderRadius: "4px",
    //       "& $notchedOutline": {
    //         borderColor: "#00CBA7",
    //       },
    //       "&:hover $notchedOutline": {
    //         borderColor: "#00CBA7",
    //       },
    //       "&$focused $notchedOutline": {
    //         borderColor: "#00CBA7",
    //       },
    //     },
    //   },
    // },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
          "&$focused": {
            color: "#FCF0CC",
          },
        },
      },
    },
  },
});
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#00CBA7",
//     },
//     secondary: {
//       main: "#508AA8",
//     },
//     tertiary: {
//       main: "#FCF0CC",
//     },
//     danger: {
//       main: "#D62246",
//     },
//     background: {
//       default: "#333745",
//       light: "#474A53",
//       paper: "#EEEEEE",
//     },
//     text: {
//       main: "#FFFFFF",
//       primary: "#1A1A1A",
//       secondary: "#FCF0CC",
//       dark: "#1A1A1A",
//     },
//   },
//   typography: {
//     fontFamily: "Roboto, sans-serif",
//     h1: {
//       fontSize: "2rem",
//       fontWeight: 700,
//       color: "#1A1A1A",
//     },
//     h2: {
//       fontSize: "1.75rem",
//       fontWeight: 700,
//       color: "white",
//     },
//     h3: {
//       fontSize: "1.75rem",
//       color: "#1A1A1A",
//     },
//     h4: {
//       fontSize: "1.5rem",
//       color: "#1A1A1A",
//     },
//     h5: {
//       fontSize: "1.2rem",
//       color: "#1A1A1A",
//     },
//     h6: {
//       fontSize: "1rem",
//       color: "#1A1A1A",
//     },
//     subtitle1: {
//       fontSize: "1rem",
//       color: "#1A1A1A",
//     },
//     subtitle2: {
//       fontSize: "0.875rem",
//       color: "#1A1A1A",
//     },
//     body1: {
//       fontSize: "1rem",
//       color: "#1A1A1A",
//     },
//     body2: {
//       fontSize: "0.875rem",
//       color: "#1A1A1A",
//     },
//     button: {
//       fontSize: "1rem",
//       color: "#1A1A1A",
//     },
//     caption: {
//       fontSize: "0.75rem",
//       color: "#1A1A1A",
//     },
//     overline: {
//       fontSize: "0.75rem",
//       color: "#1A1A1A",
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: "none",
//           color: "#1A1A1A",
//           fontWeight: 700,
//         },
//         link: {
//           color: "#508AA8",
//           fontWeight: 500,
//           textDecoration: "underline",
//           "&:hover": {
//             textDecoration: "underline",
//           },
//           "&:active": {
//             textDecoration: "underline",
//           },
//         },
//       },
//     },
//     MuiIconButton: {
//       styleOverrides: {
//         root: {
//           color: "white",
//           backgroundColor: "#00CBA7",
//         },
//       },
//     },
//     MuiTable: {
//       styleOverrides: {
//         root: {
//           "& .MuiTableCell-root": {
//             color: "#1A1A1A",
//           },
//           "& .MuiTableCell-head": {
//             color: "white",
//             fontWeight: 700,
//             backgroundColor: "#00CBA7",
//           },
//           "& .MuiTableCell-body": {
//             backgroundColor: "#EBEBEB",
//             border: "1px solid #00CBA7",
//           },
//           "& .MuiTableRow-root": {},
//         },
//       },
//     },
//   },
// });

export default theme;
