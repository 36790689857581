import React, { useState, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import getClient from "../helpers/get-client";
import { Stack, Chip, ListItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Custom filter function to disable filtering
const filterOptions = (options) => options;

const DxFinder = () => {
  const client = useMemo(() => getClient(), []);
  const [keywords, setKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);

  const handleSearchWordChange = (event, newInputValue) => {
    setSearchWord(newInputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (newInputValue) {
        setLoading(true);
        fetchKeywords(newInputValue);
      } else {
        setKeywords([]);
      }
    }, 900); // 2 seconds debounce
  };

  const fetchKeywords = (searchWord) => {
    if (!client) return;
    if (!searchWord) {
      setKeywords([]);
      return;
    };
    client
      .get(`${process.env.REACT_APP_BACKEND_URI}api/medical_analysis/get-related-keywords/`, {
        params: { searchWord }
      })
      .then((response) => {
        setKeywords(response.data.related_keywords);
      })
      .catch((error) => {
        console.error("Error fetching keywords:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeywordSelect = (event, newValue) => {
    if (newValue && !selectedKeywords.includes(newValue)) {
      setSelectedKeywords([...selectedKeywords, newValue]);
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setSelectedKeywords((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          margin: "auto",
          mt: 4,
          backgroundColor: "background.default",
          padding: 2,
          borderRadius: 4,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Search Input */}
        <Box>
          <Typography variant="h5" component="div" gutterBottom>
            Search
          </Typography>
          <Autocomplete
            freeSolo
            options={keywords}
            inputValue={searchWord}
            onInputChange={handleSearchWordChange}
            filterOptions={filterOptions} // Use custom filter function
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Enter a keyword to search"
                sx={{
                  color: "white",
                  backgroundColor: "background.default",
                  borderRadius: "4px",
                  "&:focus": {
                    backgroundColor: "background.default",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={handleKeywordSelect}
            renderOption={(props, option) => (
              <ListItem {...props} style={{ color: "black", backgroundColor: "white" }}>
                {option}
              </ListItem>
            )}
          />
        </Box>

        {/* Selected Keywords */}
        <Box>
          <Typography variant="h6" component="div" gutterBottom>
            Selected Keywords
          </Typography>
          <Stack direction="row" spacing={1}>
            {selectedKeywords.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                onDelete={handleDeleteChip(keyword)}
                color="primary"
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default DxFinder;
