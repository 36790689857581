import React from "react";
import Box from "@mui/material/Box";
import CustomRecordCard from "../../components/cards/record-structure/CustomRecordCard";
import Stack from "@mui/material/Stack";
import RecordStructure from "../record/RecordStructure";

import renderMethodMap from "../record/render-methods";
import propertyConfig from "../record/property-config";

const FindingDetail = ({ name, finding }) => {
  console.log("finding", finding);
  return (
    <Box>
      <Stack direction="column" spacing={2}>
        {finding && (
          <RecordStructure name={name}>
            {Object.entries(finding).map(([key, value]) => {
              const config = propertyConfig[key];
              if (!config) return null; // Skip properties not in the config

              const RenderComponent = renderMethodMap[config.renderMethod];
              if (!RenderComponent) return null; // Skip if no render method found

              return (
                <Box key={key}>
                  <CustomRecordCard title={config.displayName}>
                    <RenderComponent
                      name={config.displayName}
                      content={value}
                    />
                  </CustomRecordCard>
                </Box>
              );
            })}
          </RecordStructure>
        )}
      </Stack>
    </Box>
  );
};

export default FindingDetail;
