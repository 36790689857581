import { TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const DxTextField = ({
  type,
  name,
  label,
  value,
  handleChange,
  required,
  error,
}) => {
  return (
    <TextField
      type={type}
      name={name}
      label={label}
      variant="filled"
      value={value}
      onChange={handleChange}
      required={required}
      error={error}
      sx={{
        backgroundColor: "background.dark",
        borderRadius: "6px 6px 0 0",
        "& .MuiFilledInput-input": {
          color: "text.primary",
        },
        "& .MuiFilledInput-underline:before": {
          borderBottom: "1px solid white",
        },
        "& .MuiFilledInput-underline:after": {},
      }}
    />
  );
};

DxTextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

DxTextField.defaultProps = {
  error: false,
};

export default DxTextField;
