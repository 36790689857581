import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ClickAwayListener,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Source, SyncProblem } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const FindingListItem = ({
  name,
  color,
  importance,
  handleChangeWeight,
  handleRemove,
}) => {
  const navigate = useNavigate();
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderBottom: "0.5px solid",
        borderColor: "secondary.main",
        padding: "0.5rem 0.5rem",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent="start"
        sx={{
          width: "100%",
        }}
      >
        {importance && (
          <Typography
            variant="body2"
            textAlign="center"
            sx={{
              width: "2rem",
              color: color,
              fontWeight: 700,
            }}
          >
            {importance}
          </Typography>
        )}
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={name}
            placement="bottom"
          >
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                width: "85%",
              }}
              onClick={handleTooltipOpen}
            >
              {name}
            </Typography>
          </Tooltip>
        </ClickAwayListener>
      </Stack>
      <Stack direction="row">
        {handleChangeWeight && (
          <IconButton
            size="small"
            sx={{
              backgroundColor: "transparent",
              color: "primary.main",
            }}
            onClick={() => {
              handleChangeWeight(name);
            }}
          >
            <SyncProblem />
          </IconButton>
        )}
        <IconButton
          size="small"
          sx={{
            backgroundColor: "transparent",
            color: "secondary.main",
          }}
          onClick={() => {
            navigate(`/record/${name}`);
          }}
        >
          <Source />
        </IconButton>
        <IconButton
          size="small"
          sx={{
            backgroundColor: "transparent",
            color: "danger.main",
          }}
          onClick={() => {
            handleRemove(name);
          }}
        >
          <Delete />
        </IconButton>
      </Stack>
    </Stack>
  );
};

FindingListItem.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  importance: PropTypes.string,
  handleChangeWeight: PropTypes.func,
  handleRemove: PropTypes.func.isRequired,
};

FindingListItem.defaultProps = {
  color: "background.paper",
  importance: undefined,
  handleChangeWeight: undefined,
};

export default FindingListItem;
