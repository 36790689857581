import React from "react";
import { Skeleton, Stack } from "@mui/material";

const SkeletonCard = () => (
  <Stack direction="column" spacing={2}>
    <Skeleton
      animation="wave"
      variant="rectangular"
      width="30%"
      height={20}
      sx={{ bgcolor: "grey.300" }}
    />
    <Skeleton
      animation="wave"
      variant="rectangular"
      width="100%"
      height={150}
      sx={{ bgcolor: "grey.300" }}
    />
  </Stack>
);

export default SkeletonCard;
