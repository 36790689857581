import React from "react";
import { Grid } from "@mui/material";
import RecordChip from "../../chips/RecordChip";

const ListProperty = ({ content }) => (
  <Grid container>
    {content.map((item, index) => (
      <RecordChip key={item.id} item={item.name} index={index} />
    ))}
  </Grid>
);

export default ListProperty;
