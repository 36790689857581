import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  age: yup.number().positive().integer().required('Age is required'),
});

const Findings = () => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '300px', margin: 'auto', mt: 4,
        backgroundColor: 'secondary',
       }}
      padding={2}
    >
      <Typography variant="h5" component="div" gutterBottom>
        Crear nuevo Findings
      </Typography>
      
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Name"
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
            sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'primary.main',
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
          />
        )}
        sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'primary.main', // default border color
              },
              '&:hover fieldset': {
                borderColor: 'primary.main', // border color on hover
              },
            },
          }}
      />
      
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Email"
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'primary.main', // default border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main', // border color on hover
                  },
                },
              }}
          />
        )}
      />
      
      <Controller
        name="age"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Age"
            type="number"
            variant="outlined"
            error={!!errors.age}
            helperText={errors.age ? errors.age.message : ''}
            sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'primary.main', // default border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main', // border color on hover
                  },
                },
              }}
          />
        )}
      />
      
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Box>
  );
};

export default Findings;
