import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
// import { DxNavbar } from "./components/navbars";
import { UserProvider } from "./components/context/UserContext";
import { DxDataProvider } from "./components/context/DxDataContext";
import { InputParamsProvider } from "./components/context/InputParamsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <InputParamsProvider>
        <DxDataProvider>
          <ThemeProvider theme={theme}>
            <div
              style={{
                backgroundColor: "#333745",
                margin: 0,
                padding: "1rem 1rem 1rem 1rem",
                height: "100%",
                // minHeight: "100vh",
                minHeight: "96.5vh",
                fontFamily: "Open Sans, sans-serif",
              }}
            >
              <App />
            </div>
          </ThemeProvider>
        </DxDataProvider>
      </InputParamsProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
