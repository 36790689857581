import TextProperty from "../../components/cards/record-structure/TextProperty";
import KeyWordProperty from "../../components/cards/record-structure/KeyWordProperty";
import ListProperty from "../../components/cards/record-structure/ListProperty";
import HyperlinkProperty from "../../components/cards/record-structure/HyperlinkProperty";

const renderMethodMap = {
  text: TextProperty,
  keyWord: KeyWordProperty,
  list: ListProperty,
  hyperlink: HyperlinkProperty,
};

export default renderMethodMap;
