import React from "react";
import PropTypes from "prop-types";
import { TextField, Typography } from "@mui/material";

const DxAgeField = ({ param, selectedValue, setValue }) => {
  return (
    // ToDo: give it a max and min value
    <TextField
      variant="outlined"
      color="tertiary"
      size="small"
      type="number"
      placeholder={param.placeholder}
      value={selectedValue}
      InputProps={{
        endAdornment: (
          <Typography
            variant="body2"
            sx={{
              color: "text.dark",
            }}
          >
            {param.adornment}
          </Typography>
        ),
      }}
      onChange={(event) => {
        setValue(event.target.value, param.name, param.type);
      }}
      sx={{
        backgroundColor: "background.paper",
        borderRadius: "0.25rem",
        width: "100%",
        "& input": {
          color: "text.dark",
        },
      }}
    />
  );
};

DxAgeField.propTypes = {
  param: PropTypes.object.isRequired,
  selectedValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default DxAgeField;
