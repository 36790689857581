import { darken } from "@material-ui/core";

// utils/colors.js
export const chipColors = [
  "#FFB3BA",
  "#FFDFBA",
  "#FFFFBA",
  "#BAFFC9",
  "#BAE1FF", // Pastel Red, Orange, Yellow, Green, Blue
  "#FFC8DD",
  "#FFAFCC",
  "#BDE0FE",
  "#A2D2FF",
  "#CDB4DB", // Pink, Light Pink, Light Blue, Sky Blue, Lavender
  "#FFE8A1",
  "#F4BFBF",
  "#A6E3E9",
  "#FFCBD2",
  "#F7D1CD", // Light Yellow, Blush, Aqua, Coral, Peach
  "#FAD02E",
  "#D4E2D4",
  "#E1D4C8",
  "#D8E3E7",
  "#FAD9A1", // Gold, Mint, Beige, Light Gray, Apricot
  "#F7C6C7",
  "#D1CFE2",
  "#C4E8E8",
  "#E2F0CB",
  "#F3EAC2", // Light Coral, Periwinkle, Aqua, Light Green, Cream
  "#D3CCE3",
  "#E9E4E1",
  "#FDE2E4",
  "#FFF1E6",
  "#E6E6FA", // Lavender, Ivory, Light Pink, Light Peach, Lavender Blush
  "#C1E1C1",
  "#FFDEE9",
  "#E4C1F9",
  "#F6C1C1",
  "#FFD1D1", // Light Green, Pink, Purple, Light Red, Light Pink
  "#D6E6FF",
  "#FFC1E3",
  "#FFE4E1",
  "#E6E1FF",
  "#D1E0E0", // Light Blue, Light Pink, Light Peach, Light Purple, Light Gray
  "#F0E68C",
  "#E1C6C8",
  "#C2E7DA",
  "#FFEBCD",
  "#F5E8E8", // Khaki, Mauve, Mint, Blanched Almond, Misty Rose
  "#F8ECD1",
  "#FAD6A5",
  "#D1F2EB",
  "#D3E4CD",
  "#E2F0CB", // Light Beige, Peach, Light Green, Sage, Light Green
];

// Generate each of the previous colors but darker
export const darkChipColors = chipColors.map((color) => {
  return darken(color, 0.1);
});
