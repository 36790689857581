import React from "react";
import { Grid, Stack } from "@mui/material";
import ActionButton from "./ActionButton";
import { AddComment, Share, ThumbDown, ThumbUp } from "@mui/icons-material";

const ActionGrid = () => (
  <Grid item xs={12} md={2}>
    <Stack
      direction="row"
      justifyContent={{ xs: "center", md: "flex-end" }}
      mt={{ xs: 1, md: 0 }}
    >
      <ActionButton>
        <ThumbUp />
      </ActionButton>
      <ActionButton>
        <ThumbDown />
      </ActionButton>
      <ActionButton>
        <AddComment />
      </ActionButton>
      <ActionButton>
        <Share />
      </ActionButton>
    </Stack>
  </Grid>
);

export default ActionGrid;
