import {
  ArrowBackIosNew,
  Cached,
  Logout,
  MonitorHeart,
} from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useCurrentUser } from "../context/UserContext";
import { logout } from "../../helpers/authentication-helpers";
import { useNavigate } from "react-router";

const DxNavbar = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const reloadPage = () => {
    if (window.location.pathname === "/waiting-for-access") {
      window.location.href = "/"; // ToDo: Eventualmente debería ser la página de inicio
    } else {
      window.location.reload();
    }
  };
  const rootRoutes = ["/", "/authentication", "/waiting-for-access"];
  const goBack = () => {
    if (rootRoutes.includes(window.location.pathname)) {
      return;
    }
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          width: "100%",
          zIndex: 1000,
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <AppBar position="static">
          <Toolbar>
            {!rootRoutes.includes(window.location.pathname) && (
              <IconButton size="large" edge="start" onClick={goBack}>
                <ArrowBackIosNew />
              </IconButton>
            )}
            <MonitorHeart sx={{ mr: 1.5, ml: 0.5, color: "text.main" }} />
            <Typography
              sx={{
                flexGrow: 1,
                fontFamily: "MuseoModerno, sans-serif",
                fontSize: "1.5rem",
              }}
            >
              SmartDx
            </Typography>
            <IconButton onClick={reloadPage}>
              <Cached />
            </IconButton>
            {!!currentUser && (
              <IconButton onClick={logout}>
                <Logout />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Toolbar />
    </>
  );
};

export default DxNavbar;
