import React from "react";
import { Stack, Typography } from "@mui/material";
import AuthenticationForm from "./authentication/AuthenticationForm";

function Authentication() {
  return (
    <Stack
      direction="column"
      spacing={3}
      alignItems="center"
      textAlign="center"
    >
      <Typography variant="h1">¡Bienvenido a SmartDx!</Typography>
      <AuthenticationForm />
    </Stack>
  );
}

export default Authentication;
