import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const DxNumberField = ({ value, setValue, maxValue }) => {
  const handleIncrement = () => {
    setValue(value + 1);
  };
  const handleDecrement = () => {
    setValue(value - 1);
  };
  const decrementAdornment = (
    <InputAdornment position="start">
      <IconButton
        onClick={handleDecrement}
        sx={{
          backgroundColor: "transparent",
          color: "tertiary.main",
        }}
        disabled={value === 0}
      >
        <Remove fontSize="large" />
      </IconButton>
    </InputAdornment>
  );
  const incrementAdornment = (
    <InputAdornment position="end">
      <IconButton
        size="small"
        onClick={handleIncrement}
        sx={{
          backgroundColor: "transparent",
          color: "tertiary.main",
        }}
        disabled={value === maxValue}
      >
        <Add fontSize="large" />
      </IconButton>
    </InputAdornment>
  );
  return (
    <TextField
      disabled
      value={value}
      type="number"
      InputProps={{
        sx: {
          height: "3.1rem",
          width: {xs: "9rem", sm: "10rem"},
          fontSize:"1.5rem",
          borderRadius: 10,
          padding: "0 0.1rem 0 0.1rem",
          backgroundColor: "background.paper",
          "& input": {
            textAlign: "center",
          },
          "& fieldset": {
            border: "none",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#1A1A1A",
          },
        },
        startAdornment: decrementAdornment,
        endAdornment: incrementAdornment,
      }}
    />
  );
};

export default DxNumberField;
