import React from "react";
import { Grid } from "@mui/material";
import RecordChip from "../../chips/RecordChip";
import { useNavigate } from "react-router-dom";

const HyperlinkProperty = ({ content }) => {
  const navigate = useNavigate();
  return (
    <Grid container>
      {content.map((item, index) => (
        <RecordChip
          key={item}
          item={item}
          index={index}
          onClick={() => {
            navigate(`/record/${item}`);
          }}
        />
      ))}
    </Grid>
  );
};

export default HyperlinkProperty;
