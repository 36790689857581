import React, { useState } from "react";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Collapse,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FindingListItem } from "../lists";
import { TransitionGroup } from "react-transition-group";

const DictInputForm = ({
  selected,
  setSelected,
  paramInfo: { name: paramName, type: paramType, placeholder },
  autoCompleteOptions,
}) => {
  const [inputValue, setInputValue] = useState("");

  const getWeightValues = (paramName) => {
    if (paramName === "anamnesis_remota") {
      return [0, 0.5, 1, 2, 3];
    }
    return [0.5, 1, 2, 3];
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const paramValue = inputValue.trim();
    if (!paramValue) return;
    setSelected({ ...selected, [paramValue]: 1 }, paramName, paramType); // ToDo: Change 2 to a variable
    setInputValue("");
  };

  const handleRemove = (key) => {
    const newSelected = { ...selected };
    delete newSelected[key];
    setSelected(newSelected, paramName, paramType);
  };

  const handleChangeWeight = (key) => {
    const newSelected = { ...selected };
    const currentWeight = newSelected[key];
    const weightValues = getWeightValues(paramName);
    const currentIndex = weightValues.indexOf(currentWeight);
    const nextIndex = (currentIndex + 1) % weightValues.length;
    newSelected[key] = weightValues[nextIndex];
    setSelected(newSelected, paramName, paramType);
  };

  function getColorBasedOnWeight(weight) {
    // make variants of #00CBA7 based on weight, where 00CBA7 is the darkest option
    switch (weight) {
      case 0:
        return "#FFFFFF";
      case 0.5:
        return "#e0f2f1";
      case 1:
        return "#b2dfdb";
      case 2:
        return "#75cbc4";
      case 3:
        return "#00CBA7";
      default:
        return "#b2dfdb";
    }
  }
  function getImportanceBasedOnWeight(weight) {
    switch (weight) {
      case 0:
        return "-";
      case 0.5:
        return "!";
      case 1:
        return "!!";
      case 2:
        return "!!!";
      case 3:
        return "!!!!";
      default:
        return "!!";
    }
  }
  return (
    <Stack direction="row">
      <Stack direction="column" sx={{ width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Autocomplete
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              freeSolo
              disablePortal
              options={autoCompleteOptions || []}
              sx={{
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={placeholder}
                  color="tertiary"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "background.paper",
                      color: "text.dark",
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body2" color="text.dark">
                    {option}
                  </Typography>
                </li>
              )}
            />
            <IconButton type="submit" color="primary" size="medium">
              <Add />
            </IconButton>
          </Stack>
        </form>
        <Stack mt={0.5}>
          <TransitionGroup>
            {Object.entries(selected).map(([key, value], index) => (
              <Collapse key={key}>
                <FindingListItem
                  key={key}
                  name={key}
                  color={getColorBasedOnWeight(value)}
                  importance={getImportanceBasedOnWeight(value)}
                  handleChangeWeight={() => handleChangeWeight(key)}
                  handleRemove={() => handleRemove(key)}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </Stack>
      </Stack>
    </Stack>
  );
};

DictInputForm.propTypes = {
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  paramInfo: PropTypes.object.isRequired,
  autoCompleteOptions: PropTypes.array,
};

export default DictInputForm;
