const getPathInfo = (specificDxName, allPathsInfo) => {
  if (!allPathsInfo) return;
  const pathsInfo = allPathsInfo.find(
    (path) => path.name_dx === specificDxName
  );
  const dxPathsInfo = pathsInfo.all_paths_info;
  return { dxPathsInfo };
};

const getGraphInfo = (specificDxName, dxPathsInfo) => {
  const edges = [];
  const uniqueNodes = new Set();

  dxPathsInfo.forEach((paths_info) => {
    const nodes_in_path = paths_info.nodes_names_in_path;

    nodes_in_path.forEach((path) => {
      for (let i = 0; i < path.length - 1; i++) {
        const fromNode = path[i];
        const toNode = path[i + 1];
        uniqueNodes.add(fromNode);
        uniqueNodes.add(toNode);
        edges.push({ from: fromNode, to: toNode });
      }
    });
  });
  return {
    nodes: Array.from(uniqueNodes),
    edges,
    startNode: specificDxName,
  };
};

export { getGraphInfo, getPathInfo };
