import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RawQuery from "./views/RawQuery";
import Authentication from "./views/Authentication";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import WaitingForAccess from "./views/WaitingForAccess";
import Record from "./views/Record";
import DxFinder from "./views/DxFinder";
import DxGraph from "./views/DxGraph";
import DxNavbar from "./components/navbars/DxNavbar";
import Findings from "./views/admin/Findings";
import Keywords from "./views/admin/Keywords";

function App() {
  return (
    <Router>
      <DxNavbar />
      <Routes>
        <Route path="/" element={<Authentication />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/waiting-for-access" element={<WaitingForAccess />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/raw-query" element={<RawQuery />} />
          <Route path="/finder" element={<DxFinder />} />
          <Route path="/record/:recordId" element={<Record />} />
          <Route path="/graph/:recordId" element={<DxGraph />} />
          <Route path="/admin/findings" element={<Findings />} />
          <Route path="/admin/keywords" element={<Keywords />} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </Router>
  );
}

export default App;
