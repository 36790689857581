import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Stack, Chip } from "@mui/material";
import getClient from "../../helpers/get-client";
import FindingDetail from "./FindingDetail";

const Keywords = () => {
  const client = useMemo(() => getClient(), []);
  const [keywords, setKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  console.log(selectedKeyword);
  const [keywordData, setKeywordData] = useState({
    finding: null,
    drug: null,
    exam: null,
  });
  const [selectedDataType, setSelectedDataType] = useState("finding"); // Default to showing findings

  useEffect(() => {
    if (!client) return;
    client
      .get(`${process.env.REACT_APP_BACKEND_URI}api/medical_analysis/keyword/`)
      .then((response) => {
        setKeywords(response.data);
      })
      .catch((error) => {
        console.error("Error fetching keywords:", error);
      });
  }, [client]);

  const handleKeywordChange = (event) => {
    const keyword = event.target.value;
    setSelectedKeyword(keyword);
    fetchKeywordData(keyword);
  };

  const fetchKeywordData = (keyword) => {
    client
      .get(
        `${process.env.REACT_APP_BACKEND_URI}api/medical_analysis/keyword-related-data/?keyword_id=${keyword?.id}`
      )
      .then((response) => {
        setKeywordData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching keyword data:", error);
      });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          margin: "auto",
          mt: 4,
          backgroundColor: "background.default",
          padding: 2,
          borderRadius: 4,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Keyword Selector */}
        <Box>
          <Typography variant="h5" component="div" gutterBottom>
            Keywords
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedKeyword || ""}
              onChange={handleKeywordChange}
              displayEmpty
              inputProps={{ "aria-label": "Select keyword" }}
              sx={{
                color: "white",
                backgroundColor: "background.default",
                borderRadius: "4px",
                "&:focus": {
                  backgroundColor: "background.default",
                },
                icon: {
                  color: "white",
                },
              }}
            >
              <MenuItem value="" disabled>
                Select a keyword
              </MenuItem>
              {keywords.map((keyword) => (
                <MenuItem key={keyword.id} value={keyword}>
                  {keyword.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Data Type Selector (Findings, Drugs, Exams) */}
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 2 }}>
          <Typography variant="subtitle1" sx={{ minWidth: "100px" }}>
            View:
          </Typography>
          <Stack direction="row" spacing={1}>
            <Chip
              label="Finding"
              color={selectedDataType === "finding" ? "primary" : "default"}
              clickable
              onClick={() => setSelectedDataType("finding")}
            />
            <Chip
              label="Drug"
              color={selectedDataType === "drug" ? "primary" : "default"}
              clickable
              onClick={() => setSelectedDataType("drug")}
            />
            <Chip
              label="Exam"
              color={selectedDataType === "exam" ? "primary" : "default"}
              clickable
              onClick={() => setSelectedDataType("exam")}
            />
          </Stack>
        </Box>
      </Box>

      {/* Related Data Tables */}
      {selectedKeyword && (
        <Box mt={2}>
          {/* Findings Table */}
          {selectedDataType === "finding" && keywordData.findings?.length && (
            <Box>
              <FindingDetail
                name={selectedKeyword.name}
                finding={keywordData.findings[0]}
              />
            </Box>
          )}

          {/* Drugs Table */}
          {selectedDataType === "drug" && keywordData.drugs && (
            <Box>
              <FindingDetail
                name={selectedKeyword.name}
                finding={keywordData.drugs[0]}
              />
            </Box>
          )}

          {/* Exams Table */}
          {selectedDataType === "exam" && keywordData.exam && (
            <Box>
              <FindingDetail
                name={selectedKeyword.name}
                finding={keywordData.exams[0]}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Keywords;
