import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { parseValue } from "../../helpers/parsers";
import { SpecificDxsModal } from "../modals";

const DxTable = ({ columnNames, rowData }) => {
  const specificDxsIndex = columnNames.indexOf("all_group_dxs_and_score");
  const groupNameIndex = columnNames.indexOf("groupdx");
  const [specificDxs, setSpecificDxs] = useState([]);
  const [openSpecificDxs, setOpenSpecificDxs] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const handleGroupClick = (index) => {
    const jsonString = rowData[specificDxsIndex][index].replace(/'/g, '"');
    const parsedDxs = JSON.parse(jsonString);
    const specificDxs = Object.entries(parsedDxs).map(([key, value]) => {
      return {
        name: key,
        score: value,
      };
    });
    setSelectedGroup(rowData[groupNameIndex][index]);
    setSpecificDxs(specificDxs);
    setOpenSpecificDxs(true);
  };
  const handleGroupClose = () => {
    setOpenSpecificDxs(false);
    setSpecificDxs([]);
  };
  return (
    <>
      <SpecificDxsModal
        openSpecificDxs={openSpecificDxs}
        specificDxs={specificDxs}
        selectedGroup={selectedGroup}
        handleGroupClose={handleGroupClose}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnNames.map((columnName, index) => (
                <TableCell key={index}>{columnName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData[0].map((_, index) => (
              <TableRow key={index}>
                {columnNames.map((columnName, columnIndex) => (
                  <TableCell key={columnName}>
                    {columnName === "groupdx" ? (
                      <Button
                        variant="link"
                        fullWidth
                        sx={{
                          padding: "0 0 0 0",
                        }}
                        onClick={() => {
                          handleGroupClick(index);
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="secondary"
                          sx={{
                            whiteSpace: "pre-wrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {parseValue(rowData[columnIndex][index])}
                        </Typography>
                      </Button>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "pre-wrap",
                          textOverflow: "ellipsis",
                          maxHeight: "6rem",
                          overflow: "scroll",
                        }}
                      >
                        {parseValue(rowData[columnIndex][index])}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

DxTable.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
    )
  ).isRequired,
};

export default DxTable;
