// ProtectedRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCurrentUser } from "../context/UserContext";
import { Typography } from "@mui/material";

const ProtectedRoute = ({ redirectPath = "/authentication", children }) => {
  const { currentUser, loading } = useCurrentUser();
  if (loading) {
    return <Typography variant="body2">Cargando...</Typography>;
  }
  if (!currentUser?.username) {
    return <Navigate to={redirectPath} replace />;
  }
  if (currentUser?.username && !currentUser?.has_access) {
    return <Navigate to="/waiting-for-access" replace />;
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
