import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { chipColors, darkChipColors } from "../../helpers/chip-colors";

const RecordChip = ({ item, index, onClick }) => {
  const getChipColor = (index) => {
    // ToDo: Hacerlo random mejor
    return chipColors[index % chipColors.length];
  };

  const getDarkChipColor = (index) => {
    return darkChipColors[index % darkChipColors.length];
  };

  const getChipFontColor = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substring(0, 2), 16);
    const g = parseInt(hexcolor.substring(2, 4), 16);
    const b = parseInt(hexcolor.substring(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "text.dark" : "text.main";
  };

  return (
    <Box
      key={item}
      sx={{
        backgroundColor: getChipColor(index),
        padding: "0.2rem 0.5rem",
        margin: "0.2rem",
        borderRadius: "0.5rem",
        cursor: onClick ? "pointer" : "default",
        // Only apply hover effect if onClick is defined
        "&:hover": {
          backgroundColor: onClick
            ? getDarkChipColor(index)
            : getChipColor(index),
        },
      }}
      onClick={onClick}
    >
      <Typography variant="body2" color={getChipFontColor(getChipColor(index))}>
        {item}
      </Typography>
    </Box>
  );
};

RecordChip.propTypes = {
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default RecordChip;
