import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useCurrentUser } from "../../components/context/UserContext";
import { register, login, logout } from "../../helpers/authentication-helpers";
import { DxTextField } from "../../components/forms";

const AuthenticationForm = () => {
  const [registrationToggle, setRegistrationToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { currentUser } = useCurrentUser();

  const submitRegistration = (event) => {
    event.preventDefault();
    register(event, formData, setFormData, setErrorMessage)
      .then(() => {
        login(event, formData, setFormData, setErrorMessage);
      })
      .catch((error) => {
        console.error("Registration error:", error);
      });
  };

  const submitLogin = (event) => {
    event.preventDefault();
    login(event, formData, setFormData, setErrorMessage);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      {!currentUser ? (
        <form
          onSubmit={registrationToggle ? submitRegistration : submitLogin}
          style={{
            width: "85%",
          }}
        >
          <Stack spacing={1.5}>
            {registrationToggle && (
              <DxTextField
                type="text"
                name="username"
                label="Nombre de usuario"
                value={formData.username}
                handleChange={handleChange}
                required
              />
            )}
            <DxTextField
              type="email"
              name="email"
              label="Correo electrónico"
              value={formData.email}
              handleChange={handleChange}
              required
            />
            <DxTextField
              type="password"
              name="password"
              label="Contraseña"
              value={formData.password}
              handleChange={handleChange}
              required
            />
          </Stack>
          {errorMessage && (
            <Typography
              variant="body2"
              sx={{
                color: "danger.main",
                marginTop: 2,
                fontWeight: 700,
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <Stack
            direction="column"
            spacing={1}
            sx={{
              marginTop: 3,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "70%",
                alignSelf: "center",
              }}
            >
              {registrationToggle ? "Registrarme" : "Ingresar"}
            </Button>
            <Button
              variant="link"
              onClick={() => setRegistrationToggle(!registrationToggle)}
              sx={{
                width: "auto",
                alignSelf: "center",
              }}
            >
              {registrationToggle
                ? "¿Ya tienes cuenta? Ingresa acá"
                : "¿Primera vez? ¡Crea una cuenta!"}
            </Button>
          </Stack>
        </form>
      ) : (
        <Stack>
          <Typography>
            Gracias por ayudarnos {currentUser.username} ❤️
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/raw-query"
            sx={{
              marginTop: 3,
            }}
          >
            Probar SmartDx
          </Button>
          <Button
            onClick={logout}
            variant="outlined"
            color="primary"
            sx={{
              marginTop: 2,
            }}
          >
            Cerrar sesión
          </Button>
        </Stack>
      )}
    </>
  );
};

export default AuthenticationForm;
