import React from "react";
import { Typography } from "@mui/material";

const TextProperty = ({ content }) => (
  <Typography
    variant="body2"
    color="text.dark"
    sx={{
      whiteSpace: "pre-wrap",
    }}
  >
    {content}
  </Typography>
);

export default TextProperty;
